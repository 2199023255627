import { Component, HostListener, OnInit } from "@angular/core";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  public desktop: Boolean;

  constructor() {}

  ngOnInit() {
    this.desktop = window.innerWidth >= 540;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.desktop = window.innerWidth >= 540;
  }
}
