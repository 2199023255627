<div [ngClass]="{ container: desktop }">
  <div [ngClass]="{ 'inside-container': desktop }">
    <h1 [ngClass]="{ 'title-mobile': !desktop }">Trainingszeiten</h1>
    <mat-grid-list
      cols="{{ cols }}"
      gutterSize="16"
      rowHeight="1.25:1"
    >
      <mat-grid-tile *ngFor="let team of teams | async">
  <mat-table
    *ngIf="team.trainings.length > 0"
    [dataSource]="team.trainings"
    class="table last-table"
  >

    <ng-container matColumnDef="time">
      <mat-header-cell *matHeaderCellDef>Zeit</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.time }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="location">
      <mat-header-cell *matHeaderCellDef>Ort</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.location }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="header-row-title">
      <mat-header-cell *matHeaderCellDef [attr.colspan]="2">
        <h2 class="table-title">{{team.name}}</h2>
      </mat-header-cell>
    </ng-container>

          <mat-header-row
            *matHeaderRowDef="['header-row-title']"
          ></mat-header-row>

          <mat-header-row
            *matHeaderRowDef="displayedColumnsTraining"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumnsTraining"
          ></mat-row>
  </mat-table>
      </mat-grid-tile>