import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import localeDeCh from "@angular/common/locales/de-CH";
import localeDeChExtra from "@angular/common/locales/extra/de-CH";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule } from "@angular/common/http";
import { TeamSiteComponent } from "./team-site/team-site.component";
import { HomeComponent } from "./home/home.component";
import { GraphQLModule } from "./graphql.module";
import { ReportSiteComponent } from "./report-site/report-site.component";
import { GamesComponent } from "./team-site/games/games.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material.module";
import { SidenavComponent } from "./sidenav/sidenav.component";
import { BoardSiteComponent } from "./board-site/board-site.component";
import { DocumentSiteComponent } from "./document-site/document-site.component";
import { FooterComponent } from "./footer/footer.component";
import { registerLocaleData } from "@angular/common";
import { SponsorSiteComponent } from "./sponsor-site/sponsor-site.component";
import { ReportsDesktopComponent } from "./home/reports-desktop/reports-desktop.component";
import { GamesDesktopComponent } from "./team-site/games-desktop/games-desktop.component";
import { TrainingSiteComponent } from "./training-site/training-site.component";

registerLocaleData(localeDeCh, localeDeChExtra);

@NgModule({
  declarations: [
    AppComponent,
    TeamSiteComponent,
    HomeComponent,
    GamesComponent,
    GamesDesktopComponent,
    SidenavComponent,
    BoardSiteComponent,
    DocumentSiteComponent,
    FooterComponent,
    ReportSiteComponent,
    SponsorSiteComponent,
    ReportsDesktopComponent,
    TrainingSiteComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    GraphQLModule,
    BrowserAnimationsModule,
    MaterialModule
   ],
  providers: [{ provide: LOCALE_ID, useValue: "de-CH" }],
  bootstrap: [AppComponent],
})
export class AppModule {}
