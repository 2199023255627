import { Component, HostListener, OnInit } from "@angular/core";
import { AllDocuments } from "../shared/services/document.service";
import { DocumentStrapi } from "../shared/models/document.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "app-document-site",
  templateUrl: "./document-site.component.html",
  styleUrls: ["./document-site.component.scss"],
})
export class DocumentSiteComponent implements OnInit {
  documents: Observable<DocumentStrapi[]>;
  categories: Observable<string[]>;
  desktop: boolean;
  constructor(private documentService: AllDocuments) {}

  ngOnInit(): void {
    this.desktop = window.innerWidth >= 540;
    this.documents = this.documentService
      .watch()
      .valueChanges.pipe(map((result) => result.data.documents));

    this.categories = this.documentService
      .watch()
      .valueChanges.pipe(
        map((result) => [
          ...new Set(
            result.data.documents.map((document) => document.category)
          ),
        ])
      );
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.desktop = window.innerWidth >= 540;
  }
}
