<ng-container *ngIf="!desktop">
  <h1 class="title">Sponsoren</h1>
  <mat-grid-list cols="cols" gutterSize="16" rowHeight="3:1">
    <mat-grid-tile *ngFor="let sponsor of sponsors | async">
      <img
        *ngIf="sponsor.logo !== null"
        src="https://cms.hcrhintal.com{{ sponsor.logo.url }}"
        class="report-img"
      />
      <div>
        <p class="mat-body-strong">
          {{ sponsor.name }}
        </p>
        <a href="{{ sponsor.URL }}">
          <mat-icon color="accent">home</mat-icon></a
        >
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</ng-container>
<div *ngIf="desktop" class="container">
  <h1 class="title">Sponsoren</h1>
  <mat-grid-list cols="3" gutterSize="16" rowHeight="2:1">
    <mat-grid-tile
      *ngFor="let sponsor of sponsors | async"
      [colspan]="1"
      [rowspan]="1"
    >
      <img
        *ngIf="sponsor.logo !== null"
        src="https://cms.hcrhintal.com{{ sponsor.logo.url }}"
        class="report-img"
      />
      <div>
        <p class="mat-body-strong">
          {{ sponsor.name }}
        </p>
        <a href="{{ sponsor.URL }}">
          <mat-icon color="accent">home</mat-icon></a
        >
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>
