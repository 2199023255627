<mat-toolbar class="mat-elevation-z4 footer-toolbar">
  <mat-toolbar-row>
    <span class="fill-space"></span>
    <h1 *ngIf="desktop">Hauptsponsoren</h1>
    <mat-divider vertical *ngIf="desktop" class="sponsor-divider"></mat-divider>
    <ng-container *ngFor="let sponsor of sponsors | async">
      <a class="footer-sponsor" href="{{ sponsor.URL }}">
        <img
          src="https://cms.hcrhintal.com{{ sponsor.logo.url }}"
          class="footer-sponsor-img"
        />
      </a>
    </ng-container>
    <span class="fill-space"></span>
  </mat-toolbar-row>
</mat-toolbar>
