import { Component, HostListener, OnInit } from "@angular/core";
import { AllBoardMembers } from "../shared/services/member.service";
import { Member } from "../shared/models/member.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "app-board-site",
  templateUrl: "./board-site.component.html",
  styleUrls: ["./board-site.component.scss"],
})
export class BoardSiteComponent implements OnInit {
  members: Observable<Member[]>;
  desktop: boolean;
  constructor(private boardMembers: AllBoardMembers) {}

  ngOnInit(): void {
    this.desktop = window.innerWidth >= 540;
    this.members = this.boardMembers
      .watch()
      .valueChanges.pipe(
        map((result) => result.data.members.sort(this.sortByPresidentFirst))
      );
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.desktop = window.innerWidth >= 540;
  }

  sortByPresidentFirst(member: Member) {
    if (member.functionDescription === "Präsident") return -1;
    return 1;
  }
}
