import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Team } from '../shared/models/team.model';
import { AllTeamsTrainings } from '../shared/services/teams.service';

@Component({
  selector: 'app-training-site',
  templateUrl: './training-site.component.html',
  styleUrls: ['./training-site.component.scss']
})
export class TrainingSiteComponent implements OnInit {

  cols: number;
  desktop = true;
  displayedColumnsTraining: string[] = ["location", "time"];
  teams: Observable<Team[]>;

  constructor(
    private allTeamsTrainings: AllTeamsTrainings,
) { }

  ngOnInit(): void {
    this.desktop = window.innerWidth >= 540;
    this.cols = this.desktop ? 3 : 1;
    this.teams = this.allTeamsTrainings
      .watch()
      .valueChanges.pipe(
        map((result) => result.data.teams.sort(this.sortTeams)));
  }

  sortTeams(a: Team, b: Team) {
    const categoryOrder = ['junior', 'female', 'male', 'unified'];

    const teamACategory = getCategory(a);
    const teamBCategory = getCategory(b);
    const teamANr = extractFirstNumber(a);
    const teamBNr = extractFirstNumber(b);

    // Sort by category first
    if (teamACategory !== teamBCategory) {
        return categoryOrder.indexOf(teamACategory) - categoryOrder.indexOf(teamBCategory);
    }
   // Then sort by age within the same category
    if (!isNaN(teamANr) && !isNaN(teamBNr)) {
        if (teamANr !== teamBNr) {
            return teamANr - teamBNr;
        }

        // If age is the same, sort by skill level
        const skillA = getSkillLevel(a.name);
        const skillB = getSkillLevel(b.name);
        return skillA - skillB;
    }

    if (isNaN(teamANr)) return -1;
    if (isNaN(teamBNr)) return 1;
}

}

 function getCategory(team: Team): string {
    if (isNachwuchs(team)) {
        return 'junior';
    }
    if (isFemale(team)) {
        return 'female';
    }
    if (isMale(team)) {
        return 'male';
    }

    if (isUnified(team)) {
        return 'unified';
    }
  }

  function isNachwuchs(team: Team) {
    if (team === null) return false;
    return !(team.name.includes("Herren") || team.name.includes("Frauen") || team.name.includes("Unified"));
  }


  function isFemale(team: Team) {
    if (team === null) return false;
    return team.name.includes("Frauen");
  }

  function isMale(team: Team) {
    if (team === null) return false;
    return team.name.includes("Herren");
  }

  function isUnified(team: Team) {
    if (team === null) return false;
    return team.name.includes("Unified");
  }

  function extractFirstNumber(team: Team): number {
    const match = team.name.match(/\d+/);
    return match ? parseInt(match[0], 10) : NaN;
}


  // Helper function to determine the skill level
function  getSkillLevel(name: string): number {
      const match = name.match(/S(\d+)/);
      if (match) {
          return parseInt(match[1], 10);
      }
      return Infinity;  // Default to a high value if no skill identifier is found
  } 