import {
  Component,
  Input,
  ViewEncapsulation,
  ChangeDetectionStrategy,
} from "@angular/core";
import { Game } from "src/app/shared/models/game.model";

@Component({
  selector: "app-games",
  templateUrl: "./games.component.html",
  styleUrls: ["./games.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GamesComponent {
  @Input("games") games: Game[];
  displayedColumnsSmall = ["date", "teamA", "teamB", "venue"];

  isPlayed(game: Game): boolean {
    return game.gameStatus === "Gespielt";
  }

  isNotPlayed(game: Game): boolean {
    return game.gameStatus !== "Gespielt";
  }

  isActual(game: Game): boolean {
    return new Date(game.gameDateTime) > new Date();
  }
}
