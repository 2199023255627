import { Component, HostListener, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Sponsor } from "../shared/models/sponsor.model";
import { AllSponsors } from "../shared/services/sponsor.service";

@Component({
  selector: "app-sponsor-site",
  templateUrl: "./sponsor-site.component.html",
  styleUrls: ["./sponsor-site.component.scss"],
})
export class SponsorSiteComponent implements OnInit {
  sponsors: Observable<Sponsor[]>;
  cols: number = 1;
  desktop: boolean;

  constructor(private sponsorService: AllSponsors) {
    this.mapSponsor = this.mapSponsor.bind(this);
    this.sortSponsors = this.sortSponsors.bind(this);
  }

  ngOnInit(): void {
    this.desktop = window.innerWidth >= 540;
    this.sponsors = this.sponsorService
      .watch()
      .valueChanges.pipe(
        map((result) => result.data.sponsors.sort(this.sortSponsors))
      );
  }

  mapSponsor = (sponsor) => {
    switch (sponsor.contract) {
      case "main":
        return 6;
      case "platin":
        return 5;
      case "gold":
        return 4;
      case "silver":
        return 3;
      case "bronze":
        return 2;
      default:
        return 1;
    }
  };

  sortSponsors(a: Sponsor, b: Sponsor) {
    return this.mapSponsor(b) - this.mapSponsor(a);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.desktop = window.innerWidth >= 540;
  }
}
