import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";
import { Member } from "../models/member.model";

export interface Response {
  members: Member[];
}

@Injectable({
  providedIn: "root",
})
export class AllBoardMembers extends Query<Response> {
  document = gql`
    query Members {
      members(where: { function: "Board" }) {
        firstName
        lastName
        email
        function
        picture {
          url
        }
        functionDescription
      }
    }
  `;
}
