<ng-container *ngIf="!desktop">
  <img
    *ngIf="(report | async)?.picture"
    src="https://cms.hcrhintal.com{{ (report | async)?.picture.url }}"
    alt=""
    class="img-fluid img-report"
  />
  <h1 *ngIf="report | async" class="margin-title">
    {{ (report | async)?.headline }}
  </h1>
  <p class="margin-text">
    {{ (report | async)?.author }}
    {{ (report | async)?.created_at | date }}
  </p>
  <p class="margin-text">
    {{ (report | async)?.content }}
  </p>
</ng-container>

<div class="container" *ngIf="desktop">
  <div class="inside-container">
    <h1 *ngIf="report | async" class="">
      {{ (report | async)?.headline }}
    </h1>
    <p class="mat-small">
      {{ (report | async)?.author }}
      {{ (report | async)?.created_at | date }}
    </p>
    <img
      *ngIf="(report | async)?.picture"
      src="https://cms.hcrhintal.com{{ (report | async)?.picture.url }}"
      alt=""
      class="img-fluid img-report"
    />
    <p class="bottom-text">
      {{ (report | async)?.content }}
    </p>
  </div>
</div>
