import { Component, OnInit } from "@angular/core";
import { AllTeams } from "../shared/services/teams.service";
import { Team } from "../shared/models/team.model";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { HostListener } from "@angular/core";

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent implements OnInit {
  teams: Observable<Team[]>;
  isMenuCollapsed = true;
  desktop = false;
  unifiedId : Observable<number>;

  constructor(private allTeamsGQL: AllTeams) {}

  ngOnInit(): void {
    this.teams = this.allTeamsGQL
      .watch()
      .valueChanges.pipe(
        map((result) => result.data.teams.sort(this.sortTeams))
      );
    this.unifiedId = this.allTeamsGQL
      .watch()
      .valueChanges.pipe(
        map((result) => {
          const unifiedTeam = result.data.teams.find(team => team.name === "Unified");
          return unifiedTeam ? Number(unifiedTeam.id) : null;
        })
      );
  }

 sortTeams(a: Team, b: Team) {
    const teamANr = extractFirstNumber(a.name);
    const teamBNr = extractFirstNumber(b.name);

    // Sort primarily by age
    if (!isNaN(teamANr) && !isNaN(teamBNr)) {
        if (teamANr !== teamBNr) {
            return teamANr - teamBNr;
        }
        
        // If age is the same, sort by skill level
        const skillA = getSkillLevel(a.name);
        const skillB = getSkillLevel(b.name);
        return skillA - skillB;
    }

    // Put teams without an age at the end
    if (isNaN(teamANr)) return -1;
    if (isNaN(teamBNr)) return +1;
  }



  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.desktop = window.innerWidth >= 540;
  }
}

  // Helper function to determine the skill level
function  getSkillLevel(name: string): number {
      const match = name.match(/S(\d+)/);
      if (match) {
          return parseInt(match[1], 10);
      }
      return Infinity;  // Default to a high value if no skill identifier is found
  } 



function extractFirstNumber(name: string): number {
    const match = name.match(/\d+/);
    return match ? parseInt(match[0], 10) : NaN;
}