import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";
import { Team } from "../models/team.model";

export interface ResponseTeams {
  teams: Team[];
}

export interface ResponseTeam {
  team: Team;
}

@Injectable({
  providedIn: "root",
})
export class AllTeams extends Query<ResponseTeams> {
  document = gql`
    query Teams {
      teams {
        name
        id
      }
    }
  `;
}

@Injectable({
  providedIn: "root",
})
export class TeamInfo extends Query<ResponseTeam> {
  document = gql`
    query Team($id: ID!) {
      team(id: $id) {
        name
        teamID
        InstagramURL
        teamPicture {
          url
        }
        trainings {
          location
          time
        }
        teamPictureCaption
        ranking
        games(sort: "gameDateTime:asc") {
          gameStatus
          venueAddress
          gameDateTime
          teamAName
          venue
          teamBName
          venueZip
          teamBScoreHT
          venueCity
          teamAScoreHT
          teamBScoreFT
          teamAScoreFT
          report {
            id
          }
        }
      }
    }
  `;
}

@Injectable({
  providedIn: "root",
})
export class AllTeamsGames extends Query<ResponseTeams> {
  document = gql`
    query Teams {
      teams(sort: "name:asc") {
        name
        games(sort: "gameDateTime:asc") {
          gameStatus
          venueAddress
          gameDateTime
          teamAName
          venue
          teamBName
          venueZip
          venueCity
          teamBScoreHT
          teamAScoreHT
          teamBScoreFT
          teamAScoreFT
          leagueShort
          report {
            id
          }
        }
      }
    }
  `;
}

@Injectable({
  providedIn: "root",
})
export class AllTeamsTrainings extends Query<ResponseTeams> {
  document = gql`
    query Teams {
      teams {
        name
        trainings {
          location
          time
        }        
      }
    }
  `;
}
