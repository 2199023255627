import { Component, HostListener, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { AllReports } from "src/app/shared/services/reports.service";
import { Report } from "src/app/shared/models/report.model";
import { map } from "rxjs/operators";
import { AllTeamsGames } from "src/app/shared/services/teams.service";
import { Game } from "src/app/shared/models/game.model";
import { isDefined } from "@angular/compiler/src/util";
import { AllGamesWithReport } from "src/app/shared/services/games.service";

@Component({
  selector: "app-reports-desktop",
  templateUrl: "./reports-desktop.component.html",
  styleUrls: ["./reports-desktop.component.scss"],
})
export class ReportsDesktopComponent implements OnInit {
  constructor(
    private allReports: AllReports,
    private allTeamsGames: AllTeamsGames,
    private allGamesWithReport: AllGamesWithReport
  ) {}

  reports: Observable<Report[]>;
  lastGamesWithReport: Observable<Game[]>;
  playedGames: Observable<Game[]>;
  nextHomeGames: Observable<Game[]>;
  nextAwayGames: Observable<Game[]>;
  cols: number;
  desktop: boolean;
  displayedColumnsSmall = ["teamAResult", "teamBResult", "result"];
  displayedColumnsNextGames = ["date", "teamA", "teamB", "venue"];

  ngOnInit() {
    this.desktop = window.innerWidth >= 540;
    this.cols = this.desktop ? 3 : 1;
    this.reports = this.allReports
      .watch()
      .valueChanges.pipe(map((result) => result.data.reports.slice(0, 3)));

    this.lastGamesWithReport = this.allGamesWithReport
      .watch()
      .valueChanges.pipe(
        map((result) => result.data.games.filter(this.hasReport))
      );

    this.playedGames = this.allTeamsGames.watch().valueChanges.pipe(
      map((result) =>
        result.data.teams
          .map((team) => team.games.filter(this.isPlayed).slice(-1)[0])
          .sort(this.sortGames)
          .filter(Boolean)
      )
    );

    this.nextHomeGames = this.allTeamsGames.watch().valueChanges.pipe(
      map((result) =>
        result.data.teams
          .map(
            (team) =>
              team.games
                .filter(this.isNotPlayed)
                .filter(this.isHome)
                .filter(this.isActual)[0]
          )
          .sort(this.sortGames)
          .filter(Boolean)
      )
    );

    this.nextAwayGames = this.allTeamsGames.watch().valueChanges.pipe(
      map((result) =>
        result.data.teams
          .map(
            (team) =>
              team.games
                .filter(this.isNotPlayed)
                .filter(this.isNotHome)
                .filter(this.isActual)[0]
          )
          .sort(this.sortGames)
          .filter(Boolean)
      )
    );
  }

  isPlayed(game: Game): boolean {
    return game.gameStatus === "Gespielt";
  }

  isNotPlayed(game: Game): boolean {
    return game.gameStatus !== "Gespielt";
  }

  isActual(game: Game): boolean {
    return new Date(game.gameDateTime) > new Date();
  }

  isHome(game: Game): boolean {
    if (isDefined(game.teamAName)) {
      return game.teamAName.includes("HC Rheintal");
    }
    return false;
  }

  isNotHome(game: Game): boolean {
    if (isDefined(game.teamBName)) {
      return game.teamBName.includes("HC Rheintal");
    }
    return false;
  }

  isHerren(game: Game): boolean {
    const leagueShortsMen = ["M1", "M2", "M3", "M4"];
    if (isDefined(game)) {
      return leagueShortsMen.some((short) => game.leagueShort.includes(short));
    }
    return false;
  }

  isFrauen(game: Game): boolean {
    const leagueShortsWomen = ["F1", "F2", "F3", "F4"];
    if (isDefined(game)) {
      return leagueShortsWomen.some((short) =>
        game.leagueShort.includes(short)
      );
    }
    return false;
  }

  isNachwuchs(game: Game): boolean {
    const leagueShortsJuniors = ["MU", "FU", "U"];
    if (isDefined(game)) {
      return leagueShortsJuniors.some((short) =>
        game.leagueShort.includes(short)
      );
    }
    return false;
  }

  hasReport(game: Game): boolean {
    if (isDefined(game)) {
      return isDefined(game.report);
    }
    return false;
  }

  sortGames(a: Game, b: Game) {
    return (
      new Date(a.gameDateTime).getTime() - new Date(b.gameDateTime).getTime()
    );
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.desktop = window.innerWidth >= 540;
    this.cols = this.desktop ? 3 : 1;
  }
}
