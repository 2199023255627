import { Component, Input, HostListener, OnInit } from "@angular/core";
import { Game } from "src/app/shared/models/game.model";

@Component({
  selector: "app-games-desktop",
  templateUrl: "./games-desktop.component.html",
  styleUrls: ["./games-desktop.component.scss"],
})
export class GamesDesktopComponent implements OnInit {
  ngOnInit(): void {
    this.desktop = window.innerWidth >= 540;
    console.log(this.games);
  }
  @Input("games") games: Game[];
  desktop: boolean;
  displayedColumnsSmall = ["date", "teamA", "teamB", "venue"];
  cols: number;

  isPlayed(game: Game): boolean {
    return game.gameStatus === "Gespielt";
  }

  isNotPlayed(game: Game): boolean {
    return game.gameStatus !== "Gespielt";
  }

  isActual(game: Game): boolean {
    return new Date(game.gameDateTime) > new Date();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.desktop = window.innerWidth >= 540;
  }
}
