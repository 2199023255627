<div class="container-inside" *ngIf="!desktop">
  <h1 class="title">Dokumente</h1>
  <mat-action-list *ngFor="let category of categories | async">
    <div mat-subheader>{{ category }}</div>
    <ng-container *ngFor="let document of documents | async">
      <mat-list-item *ngIf="document.category === category">
        <a
          class="mat-flat-button btn-form"
          download
          href="https://cms.hcrhintal.com{{ document.pdf.url }}"
          target="_blank"
          color="accent"
        >
          {{ document.name }}
        </a>
      </mat-list-item>
    </ng-container>
  </mat-action-list>
</div>
<div class="container" *ngIf="desktop">
  <div class="container-inside">
    <h1 class="title">Dokumente</h1>
    <mat-action-list *ngFor="let category of categories | async">
      <div mat-subheader>{{ category }}</div>
      <ng-container *ngFor="let document of documents | async">
        <a
          *ngIf="document.category === category"
          class="mat-flat-button btn-form"
          download
          href="https://cms.hcrhintal.com{{ document.pdf.url }}"
          target="_blank"
          color="accent"
        >
          {{ document.name }}
        </a>
      </ng-container>
    </mat-action-list>
  </div>
</div>
