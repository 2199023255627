<mat-grid-list
  *ngIf="games && games.filter(isPlayed).length"
  cols="{{
    games.filter(isPlayed).length < 5 ? games.filter(isPlayed).length : 5
  }}"
  rowHeight="{{ games.filter(isPlayed).length < 4 ? '3:2' : '1:1.5' }}"
  gutterSize="8px"
>
  <mat-grid-tile
    [colspan]="1"
    [rowspan]="1"
    *ngFor="let game of games.filter(isPlayed).reverse()"
  >
    <mat-card class="card-games">
      <mat-card-title>
        <p class="mat-small">
          {{ game.gameDateTime | date }}
        </p>
      </mat-card-title>
      <mat-card-content>
        <p
          class="mat-small"
          [ngClass]="{ bold: game.teamAName.includes('HC Rheintal') }"
        >
          {{ game.teamAName | slice: 0:21 }}
        </p>
        <p class="mat-small">vs.</p>
        <p
          class="mat-small"
          [ngClass]="{ bold: game.teamBName.includes('HC Rheintal') }"
        >
          {{ game.teamBName | slice: 0:21 }}
        </p>
      </mat-card-content>
      <mat-card-footer>
        <p class="mat-headline">
          <span [ngClass]="{ bold: game.teamAName.includes('HC Rheintal') }">
            {{ game.teamAScoreFT }}
          </span>
          :
          <span [ngClass]="{ bold: game.teamBName.includes('HC Rheintal') }">
            {{ game.teamBScoreFT }}
          </span>
        </p>
        <div *ngIf="game.report">
          <a
            class="mat-small bold mat-flat-button btn-report"
            color="accent"
            [routerLink]="['/report/', game.report.id]"
          >
            Bericht lesen
          </a>
        </div>
      </mat-card-footer>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>

<h4
  *ngIf="games && games.filter(isNotPlayed).filter(isActual).length"
  class="title-next-games"
>
  Nächste Spiele
</h4>
<mat-table
  [dataSource]="games.filter(isNotPlayed).filter(isActual)"
  *ngIf="games && games.filter(isNotPlayed).filter(isActual).length"
  class="table"
>
  <ng-container matColumnDef="date">
    <mat-header-cell *matHeaderCellDef>Datum</mat-header-cell>
    <mat-cell *matCellDef="let element">{{
      element.gameDateTime | date: "short":"+0000"
    }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="teamA">
    <mat-header-cell *matHeaderCellDef>Heimteam</mat-header-cell>
    <mat-cell
      *matCellDef="let element"
      [ngClass]="{ bold: element.teamAName.includes('HC Rheintal') }"
      >{{ element.teamAName }}</mat-cell
    >
  </ng-container>

  <ng-container matColumnDef="teamB">
    <mat-header-cell *matHeaderCellDef>Gastteam</mat-header-cell>
    <mat-cell
      *matCellDef="let element"
      [ngClass]="{ bold: element.teamBName.includes('HC Rheintal') }"
      >{{ element.teamBName }}</mat-cell
    >
  </ng-container>

  <ng-container matColumnDef="venue">
    <mat-header-cell *matHeaderCellDef>Ort</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.venue }}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumnsSmall"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumnsSmall"></mat-row>
</mat-table>
