import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AllTeams } from "../shared/services/teams.service";
import { Team } from "../shared/models/team.model";
import { map } from 'rxjs/operators';

@Component({
  template: '',  // empty template, since we don't want to display anything
})
export class RedirectUnifiedComponent implements OnInit {

  constructor(private router: Router, private allTeamsGQL: AllTeams) {}

  ngOnInit() {
    this.allTeamsGQL.watch().valueChanges.pipe(
      map((result) => {
        const unifiedTeam = result.data.teams.find(team => team.name === 'Unified');
        if (unifiedTeam) {
          this.router.navigate([`/team/${unifiedTeam.id}`]);
        } else {
          // handle the case when the team "unified" does not exist, for example, navigate to a 404 page
          this.router.navigate(['/404']);
        }
      })
    ).subscribe();
  }

}



