import { Component, HostListener, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { ReportInfo } from "src/app/shared/services/reports.service";
import { Report } from "src/app/shared/models/report.model";
import { map, mergeMap } from "rxjs/operators";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-report",
  templateUrl: "./report-site.component.html",
  styleUrls: ["./report-site.component.scss"],
})
export class ReportSiteComponent implements OnInit {
  constructor(private reportInfo: ReportInfo, private route: ActivatedRoute) {}

  report: Observable<Report>;
  desktop: boolean;

  ngOnInit() {
    this.desktop = window.innerWidth >= 540;
    this.report = this.route.params.pipe(
      map((params) => params.id),
      mergeMap((id) =>
        this.reportInfo
          .watch({ id })
          .valueChanges.pipe(map((result) => result.data.report))
      )
    );
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.desktop = window.innerWidth >= 540;
  }
}
