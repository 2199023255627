import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { TeamSiteComponent } from "./team-site/team-site.component";
import { HomeComponent } from "./home/home.component";
import { BoardSiteComponent } from "./board-site/board-site.component";
import { DocumentSiteComponent } from "./document-site/document-site.component";
import { ReportSiteComponent } from "./report-site/report-site.component";
import { SponsorSiteComponent } from "./sponsor-site/sponsor-site.component";
import { TrainingSiteComponent } from "./training-site/training-site.component";
import { RedirectUnifiedComponent } from "./redirect/redirect.component";

const routes: Routes = [
  { path: "team/:id", component: TeamSiteComponent },
  { path: "report/:id", component: ReportSiteComponent },
  { path: "board", component: BoardSiteComponent },
  { path: "documents", component: DocumentSiteComponent },
  { path: "sponsors", component: SponsorSiteComponent },
  { path: "trainings", component: TrainingSiteComponent },
  { path: "unified", component: RedirectUnifiedComponent },
  { path: "", component: HomeComponent },
  { path: "**", component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
