import { Component, HostListener, OnInit } from "@angular/core";
import { AllSponsors } from "../shared/services/sponsor.service";
import { Sponsor } from "../shared/models/sponsor.model";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  sponsors: Observable<Sponsor[]>;
  public center: Boolean;
  public desktop: Boolean;
  private numSponsors: number;

  constructor(private sponsorService: AllSponsors) {}

  ngOnInit(): void {
    this.center = window.innerWidth >= 540 || window.innerWidth <= 375;
    this.desktop = window.innerWidth >= 540;
    this.numSponsors = window.innerWidth > 375 ? 3 : 2;

    this.sponsors = this.sponsorService
      .watch()
      .valueChanges.pipe(
        map((result) =>
          result.data.sponsors
            .filter(this.isMainPartner)
            .slice(0, this.numSponsors)
        )
      );
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.center = window.innerWidth >= 540;
    this.desktop = window.innerWidth >= 540;
  }

  isMainPartner(sponsor: Sponsor) {
    return sponsor.contract === "main";
  }
}
