import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";
import { Sponsor } from "../models/sponsor.model";

export interface Response {
  sponsors: Sponsor[];
}

@Injectable({
  providedIn: "root",
})
export class AllSponsors extends Query<Response> {
  document = gql`
    query Spnsors {
      sponsors {
        name
        URL
        logo {
          url
        }
        contract
      }
    }
  `;
}
