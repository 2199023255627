import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";
import { DocumentStrapi } from "../models/document.model";

export interface Response {
  documents: DocumentStrapi[];
}

@Injectable({
  providedIn: "root",
})
export class AllDocuments extends Query<Response> {
  document = gql`
    query Documents {
      documents {
        name
        category
        pdf {
          url
        }
      }
    }
  `;
}
