<mat-toolbar color="primary" class="mat-elevation-z4 header-toolbar">
  <mat-toolbar-row class="header-toolbar-row">
    <a
      class="btn-sm"
      href="https://www.facebook.com/profile.php?id=100009160087958"
      target="_blank"
    >
      <span
        class="iconify"
        data-icon="akar-icons:facebook-fill"
        data-inline="false"
      ></span>
    </a>
    <span class="fill-little-space"></span>
    <a
      href="https://www.instagram.com/hcrheintal/"
      target="_blank"
      class="btn-sm"
    >
      <span
        class="iconify"
        data-icon="mdi-instagram"
        data-inline="false"
      ></span>
    </a>
    <span class="fill-space"></span>
    <img
      src="../../assets/img/logo.png"
      (click)="sidenav.close()"
      routerLink="/"
      class="header-logo"
    />
    <span class="fill-space"></span>
    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon color="accent" class="btn-burger">menu</mat-icon>
    </button>
    <span *ngIf="desktop" class="fill-little-space"></span>
  </mat-toolbar-row>
</mat-toolbar>
<mat-sidenav-container class="">
  <mat-sidenav #sidenav position="end">
    <mat-nav-list>
      <a
        mat-list-item
        [routerLink]="['/']"
        (click)="
          sidenav.toggle();
          juniorsPanel.close();
          herrenPanel.close();
          frauenPanel.close()
        "
      >
        Startseite
      </a>
    </mat-nav-list>
    <mat-expansion-panel #juniorsPanel>
      <mat-expansion-panel-header class="expansion-panel-header">
        <mat-panel-title> Nachwuchs </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngFor="let team of teams | async">
        <mat-nav-list
          *ngIf="!team.name.includes('Herren') && !team.name.includes('Frauen') && !team.name.includes('Unified')"
        >
          <a
            mat-list-item
            [routerLink]="['/team/', team.id]"
            (click)="
              sidenav.toggle();
              juniorsPanel.close();
              herrenPanel.close();
              frauenPanel.close()
            "
          >
            {{ team.name }}</a
          >
        </mat-nav-list>
      </ng-container>
    </mat-expansion-panel>
    <mat-expansion-panel #frauenPanel>
      <mat-expansion-panel-header class="expansion-panel-header">
        <mat-panel-title> Frauen </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngFor="let team of teams | async">
        <mat-nav-list *ngIf="team.name.includes('Frauen')">
          <a
            mat-list-item
            [routerLink]="['/team/', team.id]"
            (click)="
              sidenav.toggle();
              juniorsPanel.close();
              herrenPanel.close();
              frauenPanel.close()
            "
          >
            {{ team.name }}</a
          >
        </mat-nav-list>
      </ng-container>
    </mat-expansion-panel>
    <mat-expansion-panel #herrenPanel>
      <mat-expansion-panel-header class="expansion-panel-header">
        <mat-panel-title> Herren </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngFor="let team of teams | async">
        <mat-nav-list *ngIf="team.name.includes('Herren')">
          <a
            mat-list-item
            [routerLink]="['/team/', team.id]"
            (click)="
              sidenav.toggle();
              juniorsPanel.close();
              herrenPanel.close();
              frauenPanel.close()
            "
          >
            {{ team.name }}</a
          >
        </mat-nav-list>
      </ng-container>
    </mat-expansion-panel>
    <mat-nav-list *ngIf="unifiedId | async as teamId; ">
      <a
        mat-list-item
        [routerLink]="['/team' , teamId]"
        (click)="
          sidenav.toggle();
          juniorsPanel.close();
          herrenPanel.close();
          frauenPanel.close()
        "
      >
        Unified
      </a>
    </mat-nav-list>
    <mat-nav-list>
      <a
        mat-list-item
        [routerLink]="['/sponsors']"
        (click)="
          sidenav.toggle();
          juniorsPanel.close();
          herrenPanel.close();
          frauenPanel.close()
        "
      >
        Sponsoren
      </a>
      <a
        mat-list-item
        download
        href="https://team.jako.ch/de/team/hc_rheintal/"
        target="_blank"
        (click)="
          sidenav.toggle();
          juniorsPanel.close();
          herrenPanel.close();
          frauenPanel.close()
        "
      >
        Shop
      </a>
      <a
        mat-list-item
        [routerLink]="['/board']"
        (click)="
          sidenav.toggle();
          juniorsPanel.close();
          herrenPanel.close();
          frauenPanel.close()
        "
      >
        Vorstand
      </a>
      <a
        mat-list-item
        [routerLink]="['/documents']"
        (click)="
          sidenav.toggle();
          juniorsPanel.close();
          herrenPanel.close();
          frauenPanel.close()
        "
      >
        Dokumente
      </a>
      <a
        mat-list-item
        [routerLink]="['/trainings']"
        (click)="
          sidenav.toggle();
          juniorsPanel.close();
          herrenPanel.close();
          frauenPanel.close()
        "
      >
        Trainingszeiten
      </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet> </router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<app-footer></app-footer>
