<ng-container *ngIf="!desktop">
  <h1 class="title">Vorstand</h1>
  <mat-grid-list cols="1" rowHeight="1:1">
    <mat-grid-tile *ngFor="let member of members | async">
      <img
        *ngIf="member.picture"
        src="https://cms.hcrhintal.com{{ member.picture.url }}"
      />
      <div>
        <p class="mat-body-strong">
          {{ member.firstName }} {{ member.lastName }}
        </p>
        <p class="mat-body">
          {{ member.functionDescription }}
        </p>
        <a href="mailto:{{ member.email }}">
          <mat-icon color="accent">email</mat-icon></a
        >
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</ng-container>
<div *ngIf="desktop" class="container">
  <div class="inside-container">
    <h1 class="title">Vorstand</h1>
    <mat-grid-list cols="3" rowHeight="1.5:1" gutterSize="24">
      <mat-grid-tile *ngFor="let member of members | async">
        <img
          *ngIf="member.picture"
          src="https://cms.hcrhintal.com{{ member.picture.url }}"
        />
        <div>
          <p class="mat-body-strong">
            {{ member.firstName }} {{ member.lastName }}
          </p>
          <p class="mat-body">
            {{ member.functionDescription }}
          </p>
          <a href="mailto:{{ member.email }}">
            <mat-icon color="accent">email</mat-icon></a
          >
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
