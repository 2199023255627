import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";
import { Report } from "../models/report.model";

export interface ResponseReports {
  reports: Report[];
}

export interface ResponseReport {
  report: Report;
}

@Injectable({
  providedIn: "root",
})
export class AllReports extends Query<ResponseReports> {
  document = gql`
    query Reports {
      reports(sort: "created_at:desc", limit: 3) {
        id
        headline
        content
        author
        created_at
        picture {
          url
        }
      }
    }
  `;
}

@Injectable({
  providedIn: "root",
})
export class ReportInfo extends Query<ResponseReport> {
  document = gql`
    query Report($id: ID!) {
      report(id: $id) {
        id
        headline
        content
        author
        created_at
        picture {
          url
        }
      }
    }
  `;
}
