<div [ngClass]="{ container: desktop }">
  <div [ngClass]="{ 'inside-container': desktop }">
    <h1 [ngClass]="{ 'title-mobile': !desktop }">News</h1>
    <mat-grid-list cols="{{ cols }}" gutterSize="16" rowHeight="2:2">
      <mat-grid-tile
        *ngFor="let report of reports | async"
        [routerLink]="['/report/', report.id]"
        class="clickable"
      >
        <img
          src="https://cms.hcrhintal.com{{ report.picture.url }}"
          class="report-img"
        />
        <span class="mat-title headline">
          {{ report.headline }}
        </span>
      </mat-grid-tile>
    </mat-grid-list>
    <h1
      class="title"
      [ngClass]="{ 'title-mobile': !desktop }"
      *ngIf="(nextHomeGames | async)?.length > 0"
    >
      Nächste Heimspiele
    </h1>
    <mat-grid-list
      cols="{{ cols }}"
      gutterSize="16"
      rowHeight="1:1"
      *ngIf="(nextHomeGames | async)?.length > 0"
    >
      <mat-grid-tile
        *ngIf="(nextHomeGames | async)?.filter(isHerren).length > 0"
      >
        <mat-table
          [dataSource]="(nextHomeGames | async)?.filter(isHerren)"
          *ngIf="nextHomeGames | async"
        >
          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef class="first-column"
              >Anpfiff</mat-header-cell
            >
            <mat-cell *matCellDef="let element" class="first-column">{{
              element.gameDateTime | date: "short":"+0000"
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="teamA">
            <mat-header-cell *matHeaderCellDef class="middle-column"
              >Heimteam</mat-header-cell
            >
            <mat-cell
              class="team-cell middle-column"
              *matCellDef="let element"
              [ngClass]="{
                bold: element.teamAName.includes('HC Rheintal')
              }"
              >{{ element.teamAName }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="teamB">
            <mat-header-cell *matHeaderCellDef class="middle-column"
              >Gastteam</mat-header-cell
            >
            <mat-cell
              class="team-cell middle-column"
              *matCellDef="let element"
              [ngClass]="{
                bold: element.teamBName.includes('HC Rheintal')
              }"
              >{{ element.teamBName }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="venue">
            <mat-header-cell *matHeaderCellDef class="last-column"
              >Ort</mat-header-cell
            >
            <mat-cell *matCellDef="let element" class="last-column">{{
              element.venue
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="header-row-title">
            <mat-header-cell *matHeaderCellDef [attr.colspan]="3">
              <h2 class="table-title">Herren</h2>
            </mat-header-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="['header-row-title']"
          ></mat-header-row>

          <mat-header-row
            *matHeaderRowDef="displayedColumnsNextGames"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumnsNextGames"
          ></mat-row>
        </mat-table>
      </mat-grid-tile>
      <mat-grid-tile
        *ngIf="(nextHomeGames | async)?.filter(isFrauen).length > 0"
      >
        <mat-table
          [dataSource]="(nextHomeGames | async)?.filter(isFrauen)"
          *ngIf="nextHomeGames | async"
        >
          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef class="first-column"
              >Anpfiff</mat-header-cell
            >
            <mat-cell *matCellDef="let element" class="first-column">{{
              element.gameDateTime | date: "short":"+0000"
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="teamA">
            <mat-header-cell *matHeaderCellDef class="middle-column"
              >Heimteam</mat-header-cell
            >
            <mat-cell
              class="team-cell middle-column"
              *matCellDef="let element"
              [ngClass]="{
                bold: element.teamAName.includes('HC Rheintal')
              }"
              >{{ element.teamAName }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="teamB">
            <mat-header-cell *matHeaderCellDef class="middle-column"
              >Gastteam</mat-header-cell
            >
            <mat-cell
              class="team-cell middle-column"
              *matCellDef="let element"
              [ngClass]="{
                bold: element.teamBName.includes('HC Rheintal')
              }"
              >{{ element.teamBName }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="venue">
            <mat-header-cell *matHeaderCellDef class="last-column"
              >Ort</mat-header-cell
            >
            <mat-cell *matCellDef="let element" class="last-column">{{
              element.venue
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="header-row-title">
            <mat-header-cell *matHeaderCellDef [attr.colspan]="3">
              <h2 class="table-title">Frauen</h2>
            </mat-header-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="['header-row-title']"
          ></mat-header-row>

          <mat-header-row
            *matHeaderRowDef="displayedColumnsNextGames"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumnsNextGames"
          ></mat-row>
        </mat-table>
      </mat-grid-tile>
      <mat-grid-tile
        *ngIf="(nextHomeGames | async)?.filter(isNachwuchs).length > 0"
      >
        <mat-table
          [dataSource]="(nextHomeGames | async)?.filter(isNachwuchs)"
          *ngIf="nextHomeGames | async"
        >
          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef class="first-column"
              >Anpfiff</mat-header-cell
            >
            <mat-cell *matCellDef="let element" class="first-column">{{
              element.gameDateTime | date: "short":"+0000"
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="teamA">
            <mat-header-cell *matHeaderCellDef class="middle-column"
              >Heimteam</mat-header-cell
            >
            <mat-cell
              class="team-cell middle-column"
              *matCellDef="let element"
              [ngClass]="{
                bold: element.teamAName.includes('HC Rheintal')
              }"
              ><p>
                {{ element.leagueShort }}
              </p></mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="teamB" class="middle-column">
            <mat-header-cell *matHeaderCellDef>Gastteam</mat-header-cell>
            <mat-cell class="team-cell middle-column" *matCellDef="let element"
              ><p>
                {{ element.teamBName.replace("Handball", "") }}
              </p></mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="venue">
            <mat-header-cell *matHeaderCellDef class="last-column"
              >Ort</mat-header-cell
            >
            <mat-cell *matCellDef="let element" class="last-column">{{
              element.venue
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="header-row-title">
            <mat-header-cell *matHeaderCellDef [attr.colspan]="3">
              <h2 class="table-title">Nachwuchs</h2>
            </mat-header-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="['header-row-title']"
          ></mat-header-row>

          <mat-header-row
            *matHeaderRowDef="displayedColumnsNextGames"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumnsNextGames"
          ></mat-row>
        </mat-table>
      </mat-grid-tile>
    </mat-grid-list>
    <h1
      class="title"
      [ngClass]="{ 'title-mobile': !desktop }"
      *ngIf="(nextAwayGames | async)?.length > 0"
    >
      Nächste Auswärtsspiele
    </h1>
    <mat-grid-list cols="{{ cols }}" gutterSize="16" rowHeight="1:1">
      <mat-grid-tile
        *ngIf="(nextAwayGames | async)?.filter(isHerren).length > 0"
      >
        <mat-table
          [dataSource]="(nextAwayGames | async)?.filter(isHerren)"
          *ngIf="nextAwayGames | async"
        >
          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef class="first-column"
              >Anpfiff</mat-header-cell
            >
            <mat-cell *matCellDef="let element" class="first-column">{{
              element.gameDateTime | date: "short":"+0000"
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="teamA">
            <mat-header-cell *matHeaderCellDef class="middle-column"
              >Heimteam</mat-header-cell
            >
            <mat-cell
              class="team-cell middle-column"
              *matCellDef="let element"
              [ngClass]="{
                bold: element.teamAName.includes('HC Rheintal')
              }"
              >{{ element.teamAName }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="teamB">
            <mat-header-cell *matHeaderCellDef class="middle-column"
              >Gastteam</mat-header-cell
            >
            <mat-cell
              class="team-cell middle-column"
              *matCellDef="let element"
              [ngClass]="{
                bold: element.teamBName.includes('HC Rheintal')
              }"
              >{{ element.teamBName }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="venue">
            <mat-header-cell *matHeaderCellDef class="last-column"
              >Ort</mat-header-cell
            >
            <mat-cell *matCellDef="let element" class="last-column">{{
              element.venue
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="header-row-title">
            <mat-header-cell *matHeaderCellDef [attr.colspan]="3">
              <h2 class="table-title">Herren</h2>
            </mat-header-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="['header-row-title']"
          ></mat-header-row>

          <mat-header-row
            *matHeaderRowDef="displayedColumnsNextGames"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumnsNextGames"
          ></mat-row>
        </mat-table>
      </mat-grid-tile>
      <mat-grid-tile
        *ngIf="(nextAwayGames | async)?.filter(isFrauen).length > 0"
      >
        <mat-table
          [dataSource]="(nextAwayGames | async)?.filter(isFrauen)"
          *ngIf="nextAwayGames | async"
        >
          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef class="first-column"
              >Anpfiff</mat-header-cell
            >
            <mat-cell *matCellDef="let element" class="first-column">{{
              element.gameDateTime | date: "short":"+0000"
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="teamA">
            <mat-header-cell *matHeaderCellDef class="middle-column"
              >Heimteam</mat-header-cell
            >
            <mat-cell
              class="team-cell middle-column"
              *matCellDef="let element"
              [ngClass]="{
                bold: element.teamAName.includes('HC Rheintal')
              }"
              >{{ element.teamAName }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="teamB">
            <mat-header-cell *matHeaderCellDef class="middle-column"
              >Gastteam</mat-header-cell
            >
            <mat-cell
              class="team-cell middle-column"
              *matCellDef="let element"
              [ngClass]="{
                bold: element.teamBName.includes('HC Rheintal')
              }"
              >{{ element.teamBName }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="venue">
            <mat-header-cell *matHeaderCellDef class="last-column"
              >Ort</mat-header-cell
            >
            <mat-cell *matCellDef="let element" class="last-column">{{
              element.venue
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="header-row-title">
            <mat-header-cell *matHeaderCellDef [attr.colspan]="3">
              <h2 class="table-title">Frauen</h2>
            </mat-header-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="['header-row-title']"
          ></mat-header-row>

          <mat-header-row
            *matHeaderRowDef="displayedColumnsNextGames"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumnsNextGames"
          ></mat-row>
        </mat-table>
      </mat-grid-tile>
      <mat-grid-tile
        *ngIf="(nextAwayGames | async)?.filter(isNachwuchs).length > 0"
      >
        <mat-table
          [dataSource]="(nextAwayGames | async)?.filter(isNachwuchs)"
          *ngIf="nextAwayGames | async"
        >
          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef class="first-column"
              >Anpfiff</mat-header-cell
            >
            <mat-cell *matCellDef="let element" class="first-column">{{
              element.gameDateTime | date: "short":"+0000"
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="teamA">
            <mat-header-cell *matHeaderCellDef class="middle-column"
              >Heimteam</mat-header-cell
            >
            <mat-cell class="team-cell middle-column" *matCellDef="let element"
              ><p>
                {{ element.teamAName.replace("Handball", "") }}
              </p></mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="teamB">
            <mat-header-cell *matHeaderCellDef class="middle-column"
              >Gastteam</mat-header-cell
            >
            <mat-cell
              class="team-cell middle-column"
              *matCellDef="let element"
              [ngClass]="{
                bold: element.teamBName.includes('HC Rheintal')
              }"
              ><p>
                {{ element.leagueShort }}
              </p></mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="venue">
            <mat-header-cell *matHeaderCellDef class="last-column"
              >Ort</mat-header-cell
            >
            <mat-cell *matCellDef="let element" class="last-column">{{
              element.venue
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="header-row-title">
            <mat-header-cell *matHeaderCellDef [attr.colspan]="3">
              <h2 class="table-title">Nachwuchs</h2>
            </mat-header-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="['header-row-title']"
          ></mat-header-row>

          <mat-header-row
            *matHeaderRowDef="displayedColumnsNextGames"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumnsNextGames"
          ></mat-row>
        </mat-table>
      </mat-grid-tile>
    </mat-grid-list>
    <h1
      class="title"
      [ngClass]="{ 'title-mobile': !desktop }"
      *ngIf="(lastGamesWithReport | async)?.length > 0"
    >
      Spielberichte
    </h1>
    <mat-grid-list cols="{{ cols }}" gutterSize="16" rowHeight="2:2">
      <mat-grid-tile
        *ngFor="let game of lastGamesWithReport | async"
        [routerLink]="['/report/', game.report.id]"
        class="clickable"
      >
        <img
          src="https://cms.hcrhintal.com{{ game.report.picture.url }}"
          class="report-img"
        />
        <span class="mat-title headline">
          <p>{{ game.report.headline }}</p>
        </span>
      </mat-grid-tile>
    </mat-grid-list>
    <h1 class="title" [ngClass]="{ 'title-mobile': !desktop }">
      Neuste Ergebnisse
    </h1>
    <mat-grid-list cols="{{ cols }}" gutterSize="16" rowHeight="1:1">
      <mat-grid-tile>
        <mat-table
          [dataSource]="(playedGames | async)?.filter(isHerren)"
          *ngIf="playedGames | async"
        >
          <ng-container matColumnDef="teamAResult">
            <mat-header-cell *matHeaderCellDef class="first-column"
              >Heimteam</mat-header-cell
            >
            <mat-cell
              class="team-cell first-column"
              *matCellDef="let element"
              [ngClass]="{
                bold: element.teamAName.includes('HC Rheintal')
              }"
              >{{ element.teamAName }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="teamBResult">
            <mat-header-cell *matHeaderCellDef class="middle-column"
              >Gastteam</mat-header-cell
            >
            <mat-cell
              class="team-cell middle-column"
              *matCellDef="let element"
              [ngClass]="{
                bold: element.teamBName.includes('HC Rheintal')
              }"
              >{{ element.teamBName }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="result">
            <mat-header-cell *matHeaderCellDef class="last-column"
              >Ergebnis</mat-header-cell
            >
            <mat-cell *matCellDef="let element" class="last-column"
              ><span
                [ngClass]="{
                  bold: element.teamAName.includes('HC Rheintal')
                }"
                >{{ element.teamAScoreFT }}</span
              >:<span
                [ngClass]="{
                  bold: element.teamBName.includes('HC Rheintal')
                }"
                >{{ element.teamBScoreFT }}</span
              ></mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="header-row-title">
            <mat-header-cell *matHeaderCellDef [attr.colspan]="3">
              <h2 class="table-title">Herren</h2>
            </mat-header-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="['header-row-title']"
          ></mat-header-row>

          <mat-header-row
            *matHeaderRowDef="displayedColumnsSmall"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumnsSmall"
          ></mat-row>
        </mat-table>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-table
          [dataSource]="(playedGames | async)?.filter(isFrauen)"
          *ngIf="playedGames | async"
        >
          <ng-container matColumnDef="teamAResult">
            <mat-header-cell *matHeaderCellDef class="first-column"
              >Heimteam</mat-header-cell
            >
            <mat-cell
              class="team-cell first-column"
              *matCellDef="let element"
              [ngClass]="{
                bold: element.teamAName.includes('HC Rheintal')
              }"
              >{{ element.teamAName }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="teamBResult">
            <mat-header-cell *matHeaderCellDef class="middle-column"
              >Gastteam</mat-header-cell
            >
            <mat-cell
              class="team-cell middle-column"
              *matCellDef="let element"
              [ngClass]="{
                bold: element.teamBName.includes('HC Rheintal')
              }"
              >{{ element.teamBName }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="result">
            <mat-header-cell *matHeaderCellDef class="last-column"
              >Ergebnis</mat-header-cell
            >
            <mat-cell *matCellDef="let element" class="last-column"
              ><span
                [ngClass]="{
                  bold: element.teamAName.includes('HC Rheintal')
                }"
                >{{ element.teamAScoreFT }}</span
              >:
              <span
                [ngClass]="{
                  bold: element.teamBName.includes('HC Rheintal')
                }"
              >
                {{ element.teamBScoreFT }}
              </span></mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="header-row-title">
            <mat-header-cell *matHeaderCellDef [attr.colspan]="3">
              <h2 class="table-title">Frauen</h2>
            </mat-header-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="['header-row-title']"
          ></mat-header-row>
          <mat-header-row
            *matHeaderRowDef="displayedColumnsSmall"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumnsSmall"
          ></mat-row>
        </mat-table>
      </mat-grid-tile>
      <mat-grid-tile>
        <mat-table
          [dataSource]="(playedGames | async)?.filter(isNachwuchs)"
          *ngIf="playedGames | async"
        >
          <ng-container matColumnDef="teamAResult">
            <mat-header-cell *matHeaderCellDef class="first-column"
              >Heimteam</mat-header-cell
            >
            <mat-cell
              class="team-cell first-column"
              *matCellDef="let element"
              [ngClass]="{
                bold: element.teamAName.includes('HC Rheintal')
              }"
            >
              <ng-container
                *ngIf="
                  element.teamAName.includes('HC Rheintal');
                  else playedAway
                "
                >{{ element.leagueShort }}</ng-container
              >
              <ng-template #playedAway>
                {{ element.teamAName.replace("Handball", "") }}
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="teamBResult">
            <mat-header-cell *matHeaderCellDef class="middle-column"
              >Gastteam</mat-header-cell
            >
            <mat-cell
              class="team-cell middle-column"
              *matCellDef="let element"
              [ngClass]="{
                bold: element.teamBName.includes('HC Rheintal')
              }"
              >{{ element.teamBName.replace("Handball", "") }}
              <ng-container *ngIf="element.teamBName.includes('HC Rheintal')">
                {{ element.leagueShort }}</ng-container
              >
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="result">
            <mat-header-cell *matHeaderCellDef class="last-column"
              >Ergebnis</mat-header-cell
            >
            <mat-cell *matCellDef="let element" class="last-column"
              ><span
                [ngClass]="{
                  bold: element.teamAName.includes('HC Rheintal')
                }"
                >{{ element.teamAScoreFT }}</span
              >:<span
                [ngClass]="{
                  bold: element.teamBName.includes('HC Rheintal')
                }"
              >
                {{ element.teamBScoreFT }}
              </span></mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="header-row-title">
            <mat-header-cell *matHeaderCellDef [attr.colspan]="3">
              <h2 class="table-title">Nachwuchs</h2>
            </mat-header-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="['header-row-title']"
          ></mat-header-row>

          <mat-header-row
            *matHeaderRowDef="displayedColumnsSmall"
          ></mat-header-row>
          <mat-row
            *matRowDef="let row; columns: displayedColumnsSmall"
          ></mat-row>
        </mat-table>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
