import { Injectable } from "@angular/core";
import { Query } from "apollo-angular";
import gql from "graphql-tag";
import { Game } from "../models/game.model";

export interface Response {
  games: Game[];
}

@Injectable({
  providedIn: "root",
})
export class AllGamesWithReport extends Query<Response> {
  document = gql`
    query Games {
      games(sort: "gameDateTime:asc") {
        gameStatus
        venueAddress
        gameDateTime
        teamAName
        venue
        teamBName
        venueZip
        teamBScoreHT
        venueCity
        teamAScoreHT
        teamBScoreFT
        teamAScoreFT
        report {
          id
          headline
          picture {
            url
          }
        }
      }
    }
  `;
}
